<template>
  <v-container>
    <v-flex>
      <h3>Atualizações</h3>
    </v-flex>
    <br />
    <v-flex class="justify-center">
      <v-card outlined>
        <v-card-text>
          <v-row class="justify-center">
            <label>Atualizar o banco de dados manualmente: Solicita ao syspos o sincronismo com os dados fornecidos pela DAC. Tal processo não será realizado se outra atualização estiver sendo realizada.</label>
          </v-row>
          <v-row justify="center" v-show="disabled">
            <v-progress-linear indeterminate color="green"></v-progress-linear>
          </v-row>
          <v-row class="justify-center">
            <v-btn class="ma-2" outlined color="green lighten-2 ml-5" :disabled="disabled" @click="asyncPostDatabaseUpdate">
              <v-icon left dark>backup</v-icon>Atualizar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-flex>
    <br />
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="updates"
        :items-per-page="20"
        :search="search"
        no-data-text="Ainda foram encontrados dados sobre atualizações"
        :loading="showLoading"
        loading-text="Buscando dados..."
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ dmy(props.item.updated_at) }}</td>
            <td class="text-xs-left">{{ dmy(props.item.finish) }}</td>
            <td class="text-xs-left">
              <v-chip :color="props.item.status.color" outlined>{{ props.item.status.status }}</v-chip>
            </td>
            <td class="text-xs-left">
              <v-chip
                :color="props.item.situation.color"
                outlined
              >{{ props.item.situation.situation }}</v-chip>
            </td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
          </tr>
        </template> -->
      </v-data-table>
    </v-flex>
  </v-container>
</template>


<script>
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  data() {
    return {
      updates: [],
      showLoading: false,
      disabled: false,
      search: "",
      headers: [
        {
          sortable: true,
          text: "Início",
          value: "updated_at",
          align: "left"
        },
        {
          sortable: true,
          text: "Término",
          value: "finish",
          align: "left"
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          align: "left"
        },
        {
          sortable: true,
          text: "Situação",
          value: "situation",
          align: "left"
        },
        {
          sortable: true,
          text: "Matrícula",
          value: "academicCode",
          align: "left"
        },
        {
          sortable: true,
          text: "Nome",
          value: "name",
          align: "left"
        }
      ]
    };
  },

  created() {
    this.asyncGetDatabaseUpdate();
  },

  computed: {
    ...mapState("databaseUpdate", ["databaseUpdate"])
  },

  methods: {
    ...mapActions("databaseUpdate", ["ActionGetDatabaseUpdate", "ActionPostDatabaseUpdate"]),

    async asyncGetDatabaseUpdate() {
      this.showLoading = true;
      try {
        await this.ActionGetDatabaseUpdate();
      } catch (err) {
        console.error("Erro ao buscar as atualizações", err);
      } finally{
        this.showLoading = false;
      }
    },

    async asyncPostDatabaseUpdate() {
      
      try{
        this.disabled = true;
        let res = await this.ActionPostDatabaseUpdate(); 
        this.disabled = false;
        if(res.status === 200)     
          await this.asyncGetDatabaseUpdate();
      } catch (err){
        console.error('Erro ao solicitar atualização', err);
      } 
    },

     dmy(date){
      return formatDate.dmy(date);
    },
  },

  watch: {
    databaseUpdate(newValue) {
      this.showLoading = true;
      this.updates = [];

      newValue.map(update => {
        this.updates.push({
          updated_at: update.updated_at,
          finish:
            update.finish == null
              ? "Não concluido"
              : update.finish,
          status: {
            status:
              update.success == null
                ? "Não definido"
                : update.success == 0
                ? "Falha"
                : "Sucesso",
            color:
              update.success == null
                ? "grey"
                : update.success == 0
                ? "red accent-2"
                : "green lighten-2"
          },
          situation: {
            situation: update.updating == false ? "Processado" : "Processando",
            color:
              update.updating == false ? "green lighten-2" : "orange lighten-2"
          },
          academicCode: update.academicCode,
          name: update.name
        });
      });
       this.showLoading = false;
    }
  }
};
</script>

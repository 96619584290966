var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-flex", [_c("h3", [_vm._v("Atualizações")])]),
      _c("br"),
      _c(
        "v-flex",
        { staticClass: "justify-center" },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("v-row", { staticClass: "justify-center" }, [
                    _c("label", [
                      _vm._v(
                        "Atualizar o banco de dados manualmente: Solicita ao syspos o sincronismo com os dados fornecidos pela DAC. Tal processo não será realizado se outra atualização estiver sendo realizada."
                      )
                    ])
                  ]),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.disabled,
                          expression: "disabled"
                        }
                      ],
                      attrs: { justify: "center" }
                    },
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            outlined: "",
                            color: "green lighten-2 ml-5",
                            disabled: _vm.disabled
                          },
                          on: { click: _vm.asyncPostDatabaseUpdate }
                        },
                        [
                          _c("v-icon", { attrs: { left: "", dark: "" } }, [
                            _vm._v("backup")
                          ]),
                          _vm._v("Atualizar ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c(
        "v-flex",
        [
          _c(
            "v-data-table",
            {
              attrs: {
                headers: _vm.headers,
                items: _vm.updates,
                "items-per-page": 20,
                search: _vm.search,
                "no-data-text":
                  "Ainda foram encontrados dados sobre atualizações",
                loading: _vm.showLoading,
                "loading-text": "Buscando dados..."
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(props) {
                    return [
                      _c("tr", [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(_vm.dmy(props.item.updated_at)))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(_vm.dmy(props.item.finish)))
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  color: props.item.status.color,
                                  outlined: ""
                                }
                              },
                              [_vm._v(_vm._s(props.item.status.status))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  color: props.item.situation.color,
                                  outlined: ""
                                }
                              },
                              [_vm._v(_vm._s(props.item.situation.situation))]
                            )
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.name))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.academicCode))
                        ])
                      ])
                    ]
                  }
                }
              ])
            },
            [_vm._v(" --> ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }